import React, { useEffect, useState } from "react";
import './styles/App.css';
import './styles/mint.css';
import myNft from './utils/gen2abi.json';
import gen1ABI from './utils/gen1abi.json';
import { ethers, providers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import Modal from 'react-modal';
// import { Link, useSearchParams } from "react-router-dom";

import {
    Web3ReactProvider,
    useWeb3React,
    UnsupportedChainIdError
} from "@web3-react/core";

import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";

import {
    URI_AVAILABLE,
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from "@web3-react/walletconnect-connector";

import { useEagerConnect, useInactiveListener } from "./hooks";

import {
    injected,
    walletconnect,
    walletlink,
} from "./Connector/Connectors";

import { Spinner } from "./Spinner";
   
    // POP IN THE MERKLE API URI:   NOTE, I HAVE MULTIPLE ACTIVE ONES NOW, SO BE CAREFUL!
    let CONFIGS_MERKLE_API = "https://standard-template-allowlist-api2.netlify.app/.netlify/functions/express/api/"; 

    // MAIN NORMAL STUFF TO DEFINE:
    let CONFIGS_DROPNAME = 'Chef Boi R Doge: Mixed Breeds (CBRDM)';  // used in ALTs and so forth
    let CONFIGS_MINT_PRICE_CALC = 0.00; // used for calculating
    let CONFIGS_PROMO_PRICE_CALC = 0.00; // used for calculating
    let CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;
    const CONFIGS_TOTALSUPPLY_CALC = 15000; // used for calculating -- I think we may need this to calculate SOLD OUT
    let CONFIGS_TOTALSUPPLY_DISPLAY = '15,000'; // used for display purposes only...

    //todo: go get this value automatically...
    let CONFIGS_NFTS_RESERVED = 0; // I think we may need this to calculate SOLD OUT
    let reserve_supply;
    let myGen1NFTs;

    let CONFIGS_WALLET_LIMIT = 200; // High limit public, 10 presale..
    let CONFIGS_TRANSACTION_LIMIT = 10; // testing only! Actual settings tbd.

    // test wallet:  0x402905d2AA5D66515D51FAF2dFA4e350634dd20A

    // GEN1: Mainnet: 0x1bc7234A2E6bF2F487771977AC22aeeD482b9A79
    // GEN1: Goerli:  0xbF2D0be3229417bF2D5C12f9fb179E7232622eA3
    const CONFIGS_GEN1_CONTRACT_ADDRESS = "0x1bc7234A2E6bF2F487771977AC22aeeD482b9A79";
    const CONFIGS_GEN1_NFTS_OWNED = 0; 
    const CONFIGS_GEN1_UNCLAIMED = 0; 

    // GEN2: Mainnet: 0x6c789Add7623C615a22b5Bb9774d31aA37B0fA2a
    // GEN2: Goerli:  0xE708FAcD39Ed66b0F738141Cab0d3081439bD7bC
    // Gen2 2nd Goerli: 0x4385Ab966c384D14Dd9C82E809f33c2a80d288cb
    const CONFIGS_CONTRACT_ADDRESS = "0x6c789Add7623C615a22b5Bb9774d31aA37B0fA2a";

    const gen1Holdings = [];
    
    let CONFIGS_CHAIN_ID = 1; // Chain ID:  1==MAINNET, 5==GOERLI
    let CONFIGS_CHAIN_DESCRIPTION = 'Mainnet'; // used in the ALERT
    let CONFIGS_NETWORK = ''; //SB: blank (mainnet), or 'goerli.' (w/ period) -- used for link building only
    const CONFIGS_JSON_METADATA_BASEURI = 'ipfs://QmVPLq3wPzs1K429DLJy8iJuSdRWoVDiTb4TA8JFi7fNGb/';
    let CONFIGS_OPENSEA_URL = 'opensea.io'; // make either 'opensea.io' or 'testnets.opensea.io'
    let CONFIGS_NFT_TITLE_SINGULAR = 'MIXED BREED';
    let CONFIGS_NFT_TITLE_PLURAL = 'MIXED BREEDS';
    let CONFIGS_URLS_TWITTER = 'https://twitter.com/ChefBoiRDoge';
    let CONFIGS_URLS_INSTAGRAM = 'https://www.instagram.com/chefboirdoge/';
    let CONFIGS_URLS_TIKTOK = 'https://www.tiktok.com/@highheatrichie?lang=en';
    let CONFIGS_URLS_DISCORD = 'https://discord.com/invite/R8wYmF6knN';
    let CONFIGS_URLS_OPENSEA_COLLECTION = 'https://'+ CONFIGS_OPENSEA_URL +'/collection/cbrdmixedbreeds';
    let CONFIGS_URLS_PROJECTWEBSITE = 'https://chefboirdoge.com/';
    let CONFIGS_CONTRACT_URL = 'https://' + CONFIGS_NETWORK + 'etherscan.io/address/' + CONFIGS_CONTRACT_ADDRESS;
    let CONFIGS_SHOW_COLLECTION = 0; // make 1 to show, 0 to NOT show NFT collection at bottom.

    // VARIOUS MESSAGES / STRINGS:
    let CONFIGS_ALLOW_LIST_MESSAGE = 'Sorry, this is an allowlist-only minting period and your wallet is not on the list. Please return for the public mint, and/or chat with a team member if you believe this to be in error.';

    let CONFIGS_BEGIN_MINT_ALERT = 'Claiming now... please click Okay and wait while we write your mint to the blockchain. Another alert will popup shortly and let you know that the mint is complete.';

    let CONFIGS_MINT_SUCCESS_ALERT = 'You have succesfully claimed your Gen2 Mixed Breeds! Your NFT(s) should appear in your wallet and on OpenSea shortly.';

    // VARIOUS ERRORS FROM SOLIDITY:
    // Fill this object with some terms that would be found from our solidity contract's errors,
    // and then we can write custom responses for the alerts (but keep them all up here):
    let SOLIDITY_ERROR_LIST = {
        1: {
            'error': '[error text snippet from wallet error]',
            'response': '[alert response]'
        },
        2: {
            'error': 'exceed the wallet limit',
            'response': 'Your transaction would exceed the wallet limit.'
        },
        3: {
            'error': 'exceed the max supply',
            'response': 'This drop is sold out and/or you are trying to purchase more than the remaining supply. Check OpenSea for the secondary market.'
        },
        4: {
            'error': 'exceed max supply',
            'response': 'This drop is sold out and/or you are trying to purchase more than the remaining supply. Check OpenSea for the secondary market.'
        },
        5: {
            'error': 'Sale is not active',
            'response': 'The sale has been disabled on the smart contract.'
        },
        6: {
            'error': 'Not enough ether sent',
            'response': 'You sent too little ETH for your purchase. If you feel this error is wrong, drop the team a note.'
        },
        7: {
            'error': 'User denied transaction',
            'response': 'The user has denied the current transaction.'
        },
        8: {
            'error': 'already claimed',
            'response': 'It looks like you have already claimed your allowlist NFTs.'
        },
        9: {
            'error': 'insufficient funds',
            'response': 'Insufficient funds. Please add enough ETH to your wallet for this NFT purchase + gas.'
        },
        10: {
            'error': 'are not allowlisted',
            'response': 'Sorry, you are not on the presale list. Please contact the team if you believe this to be an error.'
        }, 
        11: {
            'error': 'exceed the transaction',
            'response': 'Sorry, you cannot purchase that many NFTs in a single transaction during this mint.'
        }
    }

    let GENERIC_RESPONSE = 'Transaction canceled. Usually if you see this, it means that you have rejected a transaction. If you feel that this message displayed because of another error, please alert the devs and we will have a look.';

// ##################################################################################################
function floatify(number){
    return parseFloat((number).toFixed(10));
 }

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const connectorsByName = {
    Metamask: injected,
    WalletConnect: walletconnect,
    Coinbase: walletlink,
};

const connectorImagesByName = {
    Metamask: './MetaMask_Fox.svg.png',
    WalletConnect: './walletconnect.png',
    Coinbase: './coinbase-logo-freelogovectors.net_.png',
};

function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
        return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
        return "You're connected to an unsupported network.";
    } else if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect
    ) {
        return "Please authorize this website to access your Ethereum account.";
    } else {
        console.error(error);
        return "An unknown error occurred. Check the console for more details.";
    }
}

function getLibrary(provider, connector) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
}

export default function() {
    return ( <Web3ReactProvider getLibrary = { getLibrary }><App/></Web3ReactProvider>) };

const App = () => {

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    // let [searchParams, setSearchParams] = useSearchParams();

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const context = useWeb3React();
    const {
        connector,
        library,
        chainId,
        account,
        activate,
        deactivate,
        active,
        error
    } = context;

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();

    // F98: 
    useEffect(() => {
        console.log("F98: running")
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEager = useEagerConnect();

    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEager || !!activatingConnector);

    const [mintCount, setMintCount] = useState(0);
    const [value, setValue] = useState(1);
    const [myNFTs, setMyNFTs] = useState([]);
    const [trackMintCount, setTrackMintCount] = useState(0);
    const [trackAnyMintCount, setTrackAnyMintCount] = useState(0);
    const [isSold, setIsSold] = useState(false);
    const [isLibrary, setIsLibrary] = useState(false);
    const [mintPrice, setMintPrice] = useState(CONFIGS_CURRENT_PRICE); 
    const [reserveSupply, setReserveSupply] = useState(CONFIGS_NFTS_RESERVED); 
    const [myGen1NFTs, setMyGen1NFTs] = useState(CONFIGS_GEN1_NFTS_OWNED); 
    const [myUnclaimedGen1IDs, setMyUnclaimedGen1IDs] = useState(CONFIGS_GEN1_UNCLAIMED); 
    const [claimsExist, setclaimsExist] = useState(false);
    const [calcHappened, setCalcHappened] = useState(false);
    
    let tokenId;
    let signer;
    let currentTotalSupply;

    const setupLibrary = async() => {
        // F01
        try {
            if (library) {
                setIsLibrary(true);
            }
            console.log("F01: Setup Library");
        } catch (error) {
            console.log(error);
        }
    }

    const setupEventListener = async() => {
        // F02
        try {
            const wallet = library;
            signer = wallet.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            // event listener
            connectedContract.on("Mint", (from, tokenId) => {
                setTrackAnyMintCount((trackAnyMintCount) => trackAnyMintCount + 1);
                console.log("F02: " + from, tokenId.toNumber());
            });

            if (mintCount >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                setMintCount(CONFIGS_TOTALSUPPLY_CALC);
                setIsSold(true);
            }
            console.log('F02: mintCount: ' + mintCount);

            if (library) {
                setIsLibrary(true);
            }

            console.log("F02: Setup event listener!");

        } catch (error) {
            console.log(error);
        }
    }

    const claimMyFreeGen2Dogs = async() => {
        //F03:        
        console.log('F03: ChainID == '+chainId);

        if (chainId !== CONFIGS_CHAIN_ID) {
            alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
            return;
        }

        try {
            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            console.log("F03: Claiming: Popping wallet open now.")
            // note: floatify This fixes Javascript's floating point math problem here...
            // see: https://stackoverflow.com/questions/588004/is-floating-point-math-broken
            let totalPrice = floatify(CONFIGS_CURRENT_PRICE * value);
            console.log('F03: Total Price: ' + value + ' NFTs @ ' + CONFIGS_CURRENT_PRICE + ' ETH == ' + totalPrice + ' ETH');

            let nftTxn = await connectedContract.claimFreeNFTs();

            console.log("F03: Claiming: Please wait...");

            alert(CONFIGS_BEGIN_MINT_ALERT);

            await nftTxn.wait();

            alert(CONFIGS_MINT_SUCCESS_ALERT);

            console.log("F03: Mint tx: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);

            setTrackMintCount(trackMintCount + 1);
            setclaimsExist(false);
            window.location.reload();

        } catch (e) {
            console.log(e)
            var error = e.toString().split(',');
            var rawErrorMessage = e.toString();
            let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
            let errorFound = 0;

            // some canned responses from above:
            for (let i = 1; i <= numSolidityErrors; i++) {
                var targetString = SOLIDITY_ERROR_LIST[i].error;
                if (rawErrorMessage.search(targetString) > 1) {
                    let theMessage = SOLIDITY_ERROR_LIST[i].response;
                    errorFound++;
                    alert(theMessage);
                    console.log(theMessage);
                }
            }

            // or if no error was found yet:
            if (!errorFound) {

                alert(GENERIC_RESPONSE);
                console.log(rawErrorMessage);

            }

        }

    }

    const getMintsReservesBalances = async() => {
        // F04: 
        try {

            const provider = library;
            const signer = provider.getSigner();
            const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

            console.log("F04: Getting mint count ...")
            let mint_count = await connectedContract.totalSupply();
            console.log("F04: " + ethers.utils.formatUnits(mint_count, 0));
            setMintCount(ethers.utils.formatUnits(mint_count, 0));
            console.log("F04: ... set mint count == " + mint_count);

            console.log("F04: Getting reserved supply...")
            let reserve_supply = await connectedContract.reservedNFTs();
            setReserveSupply(ethers.utils.formatUnits(reserve_supply, 0));
            console.log("F04: ... reserved supply == "+reserve_supply);
            
            console.log("F04: Getting gen2 user balance...");
            const userBalance = await connectedContract.balanceOf(account);
            console.log("F04: Gen2 userBalance == " + userBalance);

            if (mint_count >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                setIsSold(true);
            }

        } catch (error) {

            console.log(error)

        }
    }


    const getTotalOwnerNFTs = async() => {
        //F25
        console.log("F25: Getting Gen1 Balance");
        let connectedContract = new ethers.Contract(CONFIGS_GEN1_CONTRACT_ADDRESS, gen1ABI.abi, signer);
        let gen1Quantity = await connectedContract.balanceOf(account);
        setMyGen1NFTs(ethers.utils.formatUnits(gen1Quantity, 0));
        console.log("F25: User Gen1 Balance: "+gen1Quantity);
        console.log("F25: Account: "+ account);
        for (let i = 0; i < gen1Quantity; i++) {
            let thisTokenID = await connectedContract.tokenOfOwnerByIndex(account, i);
            gen1Holdings[i] = thisTokenID;
            console.log("F25: Token "+ (i+1) + " of " + gen1Quantity + " is " + thisTokenID);
        }

    }

    const calcNumAvailClaims = async() => {
        //F30
        alert('Calculating your free claim eligibility. (May take a moment if you own many Gen1 mutts. Click okay and hang out for a moment.)');
        console.log("F30: Calculating available claims.");
        console.log("F30: gen1Holdings: " + gen1Holdings);
        const provider = library;
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);
        let claimedCount=0;
        let unclaimedCount=0;
        for (let i = 0; i < gen1Holdings.length; i++) {
            let thisParticularID = gen1Holdings[i];
            let isClaimed = await connectedContract.claimedIDs(thisParticularID);
            if (isClaimed==false) { 
                unclaimedCount++; 
                console.log("ID " + thisParticularID + " NOT claimed. Count = " + unclaimedCount);
            } else {
                claimedCount++; 
                console.log("ID " + thisParticularID + " WAS claimed. Count = " + claimedCount);
            }
        }
        setMyUnclaimedGen1IDs(ethers.utils.formatUnits(unclaimedCount, 0));
        if ( unclaimedCount > 0 ) { setclaimsExist(true); }
        setCalcHappened(true);

    }

    const onDisconnect = async() => {
        console.log("Killing the wallet connection", library);
        setIsLibrary(false);
        // disconnect wallet
        deactivate();
        console.log('disconnect acct: '+account);
        console.log('disconnect lib: '+library);
        console.log('disconnect conn: '+connector);
        CONFIGS_CURRENT_PRICE = CONFIGS_MINT_PRICE_CALC;  
        setMintPrice(CONFIGS_CURRENT_PRICE);
        console.log('disconnect mint price: '+CONFIGS_CURRENT_PRICE);
        
    }

    //UE01: 
    useEffect(() => {
        console.log("UE01: Here.");
        setupLibrary();
    });

    //UE02: 
    useEffect(() => {
        console.log("UE02: Here.");
        if (library) { setupEventListener() };
    }, [isLibrary]);
    
    //UE03:
    useEffect(() => {
        console.log("UE03: Library " + isLibrary);
        console.log("UE03: TrackAnyMintCount "+ trackAnyMintCount);
        if (library) { getMintsReservesBalances() }
    }, [isLibrary, trackAnyMintCount]);

    //UE04: 
    useEffect(() => {
        console.log("UE04: Here.");
        if (library) { getTotalOwnerNFTs() }
    }, [isLibrary, trackMintCount]);

    /*
    //UE05: 
    useEffect(() => {
        console.log("UE05: Here.");
        if (library) { calcNumAvailClaims() }
    }, [isLibrary, trackMintCount]);
    */

    return (

        <div className="App">

            <div className="header" >

                <div className="headerLeft">

                    <a className="logowords" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_PROJECTWEBSITE }><img src="gen1logo.png" className="thelogo" alt={CONFIGS_DROPNAME+' Home'} title={CONFIGS_DROPNAME+' Home'} /></a> 

                </div>

                <div className="headerRight">

                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TWITTER }><img src="logo-twitter.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Twitter'} title={'Follow ' + CONFIGS_DROPNAME + ' on Twitter'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_INSTAGRAM }><img src="logo-instagram.png" alt={'Follow ' + CONFIGS_DROPNAME + ' on Instagram'} title={'Follow ' + CONFIGS_DROPNAME + ' on Instagram'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TIKTOK }><img src="logo-tiktok.png" alt={'Catch ' + CONFIGS_DROPNAME + ' on TikTok'} title={'Catch ' + CONFIGS_DROPNAME + ' on TikTok'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_DISCORD }><img src="logo-discord.png" alt={'Join the ' + CONFIGS_DROPNAME + ' Discord'} title={'Join the ' + CONFIGS_DROPNAME + ' Discord'} /></a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_OPENSEA_COLLECTION }><img src="logo-opensea.png" alt={'View the ' + CONFIGS_DROPNAME + ' collection on OpenSea'} title={'View the ' + CONFIGS_DROPNAME + ' collection on OpenSea'} /></a> 
                    <a className="pageButton marginright" target="_blank" rel="noreferrer" href={ CONFIGS_CONTRACT_URL }><img src="logo-etherscan.png" alt={'View the ' + CONFIGS_DROPNAME + ' verified contract on Etherscan'} title={'View the ' + CONFIGS_DROPNAME + ' verified contract on Etherscan'} /></a> 
                </div>

            </div>

            <div className="clear"> </div>

            <div className="mintBoxWrapper" >

                <div className="mintBoxLeft" >

                    <div className="allButMobile">
                        <img src="featured.gif" alt={CONFIGS_DROPNAME} />
                    </div>

                </div>

                <div className= "mintBoxRight" >

                    <div className="mintBoxRightWelcome">

                        <img className= "mobileOnly" src="featured.gif" alt="" />
                        
                        <p className="supplyInfo">CLAIM YOUR FREE GEN2 MIXED BREEDS!</p>

                        <ul>
                            <li>For each Gen1 Mutt you own, you may free claim a Gen2 Mixed Breed. You can do that right here!</li>
                            <li>To purchase a Gen1 Mutt (and then claim your Gen2 after), go to our <a href="https://mint.chefboirdoge.com/">Gen1 Mutt mint page</a>.</li>
                            <li>To purchase a Gen2 Mixed Breed NFT directly, go to our <a href="https://mintgen2.chefboirdoge.com/">Gen2 Mixed Breeds mint page</a>.</li>
                        </ul>

                    </div>

                    <div id="DAppArea" >

                        {
                            (active === false) ? ( 

                                <div id="prepare">

                                    <button onClick = { openModal } className="btn-primary pageButton" id="connectButton" type="button" >Connect Wallet</button> 

                                    <Modal isOpen = { modalIsOpen } onAfterOpen = { afterOpenModal } onRequestClose = { closeModal } style = { customStyles } contentLabel = "Wallet Connection"> 

                                        <h2 ref = { (_subtitle) => (subtitle = _subtitle) }>Choose Wallet Provider</h2>
                                                                                
                                        {
                                            Object.keys(connectorsByName).map(name => {
                                                const currentConnector = connectorsByName[name];
                                                const activating = currentConnector === activatingConnector;
                                                const connected = currentConnector === connector;

                                                return (
                                                    <button className="providerChoices" key={ name } onClick = { () => {
                                                            setActivatingConnector(currentConnector);
                                                            activate(connectorsByName[name]);
                                                            closeModal(); }}>
                                                        <div style = { { position: "absolute", top: "0", left: "0", height: "100%", display: "flex", alignItems: "center", color: "black", margin: "0 0 0 1rem" } }> { activating && ( <Spinner color={"black"} style={{height:"25%", marginLeft: "-1rem" } } /> ) }
                                                        </div> 
                                                        <img className='logo-wallet' src={ connectorImagesByName[name] } alt="" /> 
                                                        <span className="walletName" > { name } </span>
                                                    </button>
                                                );

                                            })
                                        }

                                    </Modal>

                                </div>

                            ):(

                                (isSold === false) ? ( 

                                    <div>                                        

                                        {/*
                                        <p className="sub-text">Total Gen2 Mints + Claims:{' '}<span className="yellow">{ mintCount }</span>{' '} / {' '} 15,000</p> 

                                        <p className="sub-text">Total Gen2 Claims Claimed:{' '}<span className="yellow">{ 10000 - reserveSupply }</span>{' '} / {' '} 10,000</p> 

                                        <p className="sub-text">Total Gen2 Claims Remaining:{' '}<span className="yellow">{ reserveSupply }</span>{' '} / {' '} 10,000</p> 
                                        */}

                                        <p className="sub-text"># Gen1 Mutts Owned <u>by You</u>:{' '}<span className="yellow">{ myGen1NFTs }</span></p> 

                                        {
                                            (calcHappened) ? (
                                                <div>
                                                    <p className="sub-text"># Gen2 Claims Already Made <u>on Your Gen1 IDs</u>:{' '}<span className="yellow">{ myGen1NFTs - myUnclaimedGen1IDs }</span></p>
                                                    <p className="sub-text"># Gen2 Claims Available <u>for You</u>:{' '}<span className="yellow">{ myUnclaimedGen1IDs }</span></p> 
                                                </div>
                                            ) : ( 
                                                <div>
                                                    <button onClick={ calcNumAvailClaims }>Determine Your Free Claim Eligibility!</button>
                                                </div> 
                                            )
                                        }

                                        
                                        {
                                            ( claimsExist === true) ? (

                                                <div>
                                                    <p className="sub-text subtextH2">You're Eligible for <span className="yellow">{ myUnclaimedGen1IDs }</span> FREE Mixed Breeds!</p>
                                                    { (myUnclaimedGen1IDs > 100) ? <p>NOTE: You can claim up to <span className="yellow">100</span> per transaction. So, reload this app when done.</p> : <span></span> }
                                                    <button onClick={ claimMyFreeGen2Dogs } className="btn-custom btn-primary pageButton" type="button" id="theMintButton">CLAIM { (myUnclaimedGen1IDs > 100) ? 100 : myUnclaimedGen1IDs } GEN2 MIXED BREEDS NOW</button> 
                                                    <p>For each Gen1 doggo you own, you will receive one Gen2 doggo.</p>
                                                </div>

                                            ) : (

                                                (calcHappened) ? ( 
                                                    <div><p>SORRY, NO CLAIMS ARE AVAILABLE FOR YOU.</p></div>
                                                ) : ( 
                                                    <div></div>
                                                )

                                            )
                                        }

                                        <div id="connected">

                                            <button onClick = { onDisconnect } className="btn btn-primary pageButton" id="disconnectButton" type="button">Disconnect <span className='smaller fixcaps'> 0x{ String(account).slice(2, 5) + "..." + String(account).slice(-4) }</span></button>

                                            <p className="buttonMessage">After claiming, head to <a target="_blank" href={ 'http://'+ CONFIGS_OPENSEA_URL + '/' + account }>your wallet on OpenSea</a> to view.</p>

                                        </div>

                                    </div>

                                ) : (

                                    <div id="totalPrice">COLLECTION IS SOLD OUT<br /><a href={CONFIGS_URLS_OPENSEA_COLLECTION}>Have a look on OpenSea</a> to grab a rare on the secondary market.</div>

                                )

                            )
                        }

                        <p><span className="soldFont" id="amountSold"> </span></p>

                    </div>

                </div>

            </div>

            <div className="clear" > </div>

        </div>

    );

};
